import React, { useState } from 'react';

const Question = ({ onCompletion }) => {
    const [userAnswers, setUserAnswers] = useState(new Array(7).fill(''));
    const correctAnswers = ["lignes", "formes", "formes 3d", "textures", "typographies", "couleurs", "espaces"];
    const [error, setError] = useState('');

    const handleChange = (index, value) => {
        const newAnswers = [...userAnswers];
        newAnswers[index] = value.toLowerCase();
        setUserAnswers(newAnswers);
    };

    const handleSubmit = () => {
        const formattedUserAnswers = userAnswers.map(answer => answer.toLowerCase().trim());
    
        // Vérifier si chaque réponse de l'utilisateur est incluse dans les réponses correctes
        const allAnswersCorrect = formattedUserAnswers.every(answer => correctAnswers.includes(answer));
    
        // Vérifier si le nombre de réponses uniques est égal au nombre de réponses correctes
        const uniqueUserAnswers = new Set(formattedUserAnswers);
        const allAnswersProvided = uniqueUserAnswers.size === correctAnswers.length;
    
        if (allAnswersCorrect && allAnswersProvided) {
            onCompletion();
        } else {
            const incorrectOrMissing = correctAnswers.length - uniqueUserAnswers.size;
            setError(`Il manque ou incorrect ${incorrectOrMissing} réponses, recommence !`);
        }
    };
    
    
    

    return (
        <div className="question-container">
            <h2>Quels sont les 7 éléments GRAPHIQUES du design graphique ? ( toutes les réponses sont à mettre au pluriel avec un s)</h2>
            {userAnswers.map((answer, index) => (
                <input
                    key={index}
                    type="text"
                    value={answer}
                    onChange={(e) => handleChange(index, e.target.value)}
                    placeholder={`Réponse ${index + 1}`}
                />
            ))}
            <button onClick={handleSubmit}>Valider</button>
            {error && <p className="error">{error}</p>}
        </div>
    );
};

export default Question;

