import React, { useState } from 'react';

const elements = [
    "ligne",
    "formes",
    "formes 3d",
    "texture",
    "typographie",
    "couleurs",
    "espace"
];

const questions = [
    "Qu’est-ce que tu peux me dire au sujet des lignes ?",
    "Qu’est-ce que tu peux me dire au sujet des formes ?",
    "Qu’est-ce que tu peux me dire au sujet des formes 3D ?",
    "Qu’est-ce que tu peux me dire au sujet des textures ?",
    "Qu’est-ce que tu peux me dire au sujet de la typographie ?",
    "Qu’est-ce que tu peux me dire au sujet des couleurs ?",
    "Qu’est-ce que tu peux me dire au sujet de l'espace ?"
];

const OpenQuestion = ({ username })=> {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [responses, setResponses] = useState(Array(elements.length).fill(''));
    const [currentInput, setCurrentInput] = useState('');
    const [quizCompleted, setQuizCompleted] = useState(false);
    

    const sendResponseToServer = (currentResponse) => {
        fetch('http://5.250.179.126:3001/submit-quiz', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, question: questions[currentQuestionIndex], response: currentResponse }),

        })
        .then(response => response.json())
        .then(data => console.log(data))
        .then(response => {
            if (!response.ok) {
                throw new Error('Réponse réseau non OK');
            }
            return response.json();
        })
        
        .catch(error => console.error('Erreur:', error));
    };

    const handleSubmit = () => {
        // Ajouter la réponse actuelle à l'ensemble des réponses
        const updatedResponses = [...responses];
        updatedResponses[currentQuestionIndex] = currentInput;
        setResponses(updatedResponses);
    
        // Envoyer la réponse actuelle au serveur
        sendResponseToServer(currentInput);
    
        // Réinitialiser l'entrée pour la prochaine question
        setCurrentInput('');
    
        // Vérifier si c'est la dernière question
        if (currentQuestionIndex === elements.length - 1) {
            setQuizCompleted(true);
            // Si tu veux envoyer toutes les réponses à la fin du quiz, tu peux le faire ici
        } else {
            // Passer à la question suivante
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };
    

    // Afficher le message de conclusion si le quiz est terminé
    if (quizCompleted) {
        return (
            <div className="open-question-container">
                <h2>Bravo, tu as répondu à toutes les questions !</h2>
                <p>Pas d’inquiétude, ce n’est pas noté. C’était simplement pour vérifier si vous aviez bien assimilé l'essentiel.</p>
                <p>Je reviendrai vers vous sur le groupe pour vous faire un feedback général, vous féliciter sur le positif et revoir ce qui doit être revu pour que tout soit bien assis avant de passer à la suite : LE DESIGN.</p>
            </div>
        );
    }

    // Afficher la question actuelle si le quiz n'est pas terminé
    return (
        <div className="open-question-container">
            <h2>{questions[currentQuestionIndex]}</h2>
            <textarea
                value={currentInput}
                onChange={(e) => setCurrentInput(e.target.value)}
                placeholder="Explique avec tes mots à toi ce que tu as retenu"
            />
            <button onClick={handleSubmit}>Soumettre</button>
        </div>
    );
};

export default OpenQuestion;
