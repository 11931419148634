import React, { useState } from 'react';

const UserInfo = ({ onNameSubmit }) => {
    const [name, setName] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        onNameSubmit(name);
    };

    return (
        <div className="user-info-container">
            <h2>Bonjour ! Quel est ton prénom ?</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Entrez votre prénom"
                />
                <button type="submit">Commencer</button>
            </form>
        </div>
    );
};

export default UserInfo;
