import React, { useState } from 'react';
import Question from './components/Question';
import OpenQuestion from './components/OpenQuestion';
import UserInfo from './UserInfo';

const App = () => {
    const [step, setStep] = useState(0); // Initialiser à 0 pour UserInfo
    const [username, setUsername] = useState(''); // Ajouter pour stocker le prénom

    const handleNameSubmit = (name) => {
        setUsername(name); // Stocker le prénom
        setStep(1); // Passer à la première question après la soumission du nom
    };

    const handleQuizCompletion = () => {
        setStep(step + 1);
    };

    return (
        <div className="quiz-container">
            {step === 0 && <UserInfo onNameSubmit={handleNameSubmit} />}
            {step === 1 && <Question onCompletion={handleQuizCompletion} />}
            {step === 2 && <OpenQuestion username={username} />} {/* Passer le prénom à OpenQuestion */}
        </div>
    );
};

export default App;
